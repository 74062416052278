import { useMediaStore } from "@/stores/blog";

export const useMedia = (article: Object | null) => {
  const { locale, t } = useI18n();
  const { $store, $api } = useNuxtApp();
  const localePath = useLocalePath();
  const store = useBlogStore();

  const storyblokApiOptions = useStoryblokApiOptions();
  const storyblokApi = useStoryblokApi();
  const { localeStoryName, links, formateDate } = useUtils();

  const resource = ref("blog");
  const page = ref(1);
  const items = ref([]);
  const totalItemsCount = ref(0);

  const mediaTypes = [
    {
      text: t("news.resources.blog.label"),
      value: "article",
    },
    {
      text: t("news.resources.podcast.label"),
      value: "podcast",
    },
    {
      text: t("news.resources.news.label"),
      value: "news",
    },
    // {
    //   text: this.$t('news.resources.video.label'),
    //   value: 'video'
    // }
  ];
  const _topics = ["leadership", "change"];
  const topics = computed(() => {
    return _topics.map((topic) => ({
      text: t(`news.topics.${topic}.label`),
      value: topic,
    }));
  });

  async function seeMore() {
    page.value++;

    const _items = await all({ page: page.value });
    items.value = [...items.value, ..._items];
  }

  watchEffect(() => {
    store.blogposts = items.value;
  });

  async function countTotalItems(params: object = {}) {
    const filters = {};

    const { data } = await storyblokApi.get("cdn/links", {
      ...storyblokApiOptions,
      starts_with: `${resource.value}/`,
      language: locale.value,
      is_startpage: 0,

      filter_query: {
        component: {
          in: "blog",
        },
        ...filters,
      },

      page: 1,
      per_page: 1000,
      ...params,
    });

    const rootPage = 1;
    totalItemsCount.value = Object.keys(data.links).length - rootPage;
    return data.links;
  }

  async function all(params: object = {}) {
    const filters = {};

    // filters.podcast_link = {
    //   is: resource.value === "podcast" ? "not_empty" : "empty",
    // };

    const { data } = await storyblokApi.get("cdn/stories", {
      ...storyblokApiOptions,
      starts_with: `${resource.value}/`,
      language: locale.value,
      is_startpage: 0,
      sort_by: "created_at:asc",

      filter_query: {
        component: {
          in: "blog",
        },
        ...filters,
      },

      page: 1,
      per_page: 6,
      ...params,
    });

    return data.stories
      .filter(
        (story) =>
          locale.value === "de" ||
          story.translated_slugs.find((slug) => slug.lang === locale.value)
            .name,
      )
      .map((story) => localeStoryName(story));
  }

  async function show(slug: string, params = {}) {
    // const storyblokLinks = computed(() => $store.getters.getLinks);
    // const link = storyblokLinks.value[uuid];

    const { data } = await storyblokApi.get(`cdn/stories/${slug}`, {
      ...storyblokApiOptions,
      ...params,
    });

    return localeStoryName(data.story);
  }

  function link(item) {
    return {
      link: {
        url:
          locale.value === "de"
            ? item.full_slug
            : item.translated_slugs.find(
                (translation) => translation.lang === locale.value,
              )?.path,
        linktype: "story",
      },
    };
  }

  const featuredItems = computed(() => {
    return store.blogposts.filter((item) => item.content.featured);
  });

  const bgColor = (item: Object) => {
    // console.log({ item });
    return item?.content?.bg_color?.color || "#e9ebed";
  };

  const keyvisual = (item: Object) => ({
    provider: false,
    blok: {
      color: bgColor(item),
      fixed: false,
      image: item?.content?.image,
    },
  });

  const generateLink = (item) => {
    return item ? `media/${item.alias_raw}` : null;
  };

  const storedLinks = computed(() => $store.getters.getBlogLinks);

  const findArticleLink = computed(() => {
    const currentLink = storedLinks.value?.find(
      (item) => item.alias_raw === article.alias_raw,
    );
    return currentLink;
  });

  const previousArticle = computed(() => {
    const currentLink = findArticleLink.value;
    return storedLinks.value[storedLinks.value.indexOf(currentLink) - 1];
  });

  const nextArticle = computed(() => {
    const currentLink = findArticleLink.value;
    // console.log(storedLinks.value[storedLinks.value.indexOf(currentLink)]);
    return storedLinks.value[storedLinks.value.indexOf(currentLink) + 1];
  });

  const getLinks = async (type: "") => {
    let links = null;
    if (!storedLinks.value?.length) {
      const { data, errorLinks } = await useAsyncData(
        "data",
        () =>
          $fetch("/api/v1/blog", {
            method: "GET",
            baseURL: "https://media.zweikern.com",
            params: {
              links: true,
              lang: locale.value,
              type,
            },
          }),
        {
          watch: [type],
        },
      );
      links = data.value.news;
      $store.dispatch("setBlogLinks", links);
    } else {
      links = storedLinks.value;
    }

    return links;
  };

  useHead({
    htmlAttrs: {
      lang: locale,
    },
    link: [
      // {
      //   rel: 'alternate',
      //   href: `${config.public.siteUrl}/${story.value.default_full_slug !== 'home' ? utils.forceTrailingSlash(story.value.default_full_slug) : ''}`,
      //   hreflang: 'de',
      //   title: story.value.name
      // },
      // ...story.value.translated_slugs.map(translation => ({
      //   rel: 'alternate',
      //   href: `${config.public.siteUrl}/${translation.lang}/${translation.path !== 'home' ? utils.forceTrailingSlash(translation.path) : ''}`,
      //   hreflang: translation.lang,
      //   title: translation.name
      // })),
      // ...story.value.translated_slugs.map(translation => ({
      //   rel: 'alternate',
      //   href: `${config.public.siteUrl}/${translation.lang}/${translation.path !== 'home' ? utils.forceTrailingSlash(translation.path) : ''}`,
      //   hreflang: 'x-default',
      //   title: translation.name
      // })),
      // {
      //   rel: 'canonical',
      //   href: config.public.siteUrl + utils.forceTrailingSlash(story.value.default_full_slug !== 'home' ? '/' + story.value.full_slug : localePath('/'))
      // }
    ],
  });

  if (article) {
    useSeoMeta({
      title: () => article.value?.headline,
      description: () => article.value?.meta_description,
    });
  }

  function itemType(item) {
    return isPodcast(item) ? "podcast" : "article";
  }

  function isPodcast(item) {
    return !!(item?.content || item).podcast_link;
  }

  function getTranslation(item) {
    const translation = item.translated_slugs.find(
      (translation) => translation.lang === locale.value,
    );
    if (translation) {
      translation.full_slug = translation.path;
    }

    return locale.value === "de" ? item : translation;
  }

  function bindItemCard(item) {
    return {
      ...item.content,
      class: "clickable",
      type: itemType(item),
      headline: item.name,
      image: {
        src: item.content.image?.filename,
        alt: item.content.image?.alt,
      },
      link: {
        url: `${getTranslation(item).full_slug}`,
        linktype: "storyblok",
      },
      isNews: resource.value === "news",
    };
  }

  function isFilteredMediaType(type: string) {
    return store.filter.mediaTypes.includes(type);
  }

  function isFilteredTopic(topic: string) {
    return store.filter.topic === topic;
  }

  function filterTopic(topic: string | null) {
    store.filter.topic = topic;
  }

  const hasFilteredMediaType = computed(
    () => store.filter.mediaTypes.length > 0,
  );

  // Fetch Blog from action
  function filterMediaType(type: string) {
    store.filter.mediaTypes = store.filter.mediaTypes.includes(type)
      ? store.filter.mediaTypes.filter((item) => item !== type)
      : [...store.filter.mediaTypes, type];
  }

  /** Add top choice items by uuids to items */
  async function addTopChoices(uuids: string[]) {
    uuids.forEach(async (uuid) => {
      if (!items.value.find((item) => item.uuid === uuid)) {
        const { data } = await storyblokApi.get(
          "cdn/stories/" + links.value[uuid].id,
          {
            ...storyblokApiOptions,
            language: locale.value,
          },
        );

        items.value.push(data.story);
      }
    });
  }

  function parseContent(content: String = "") {
    return content
      ? content
          .replace(/de\/news/g, "/blog")
          .replace(/en\/news/g, "/en/blog")
          .replace(/de\/lexikon/g, "/lexikon")
          .replace(
            /https:\/\/zweikern.com\/leistungen\/zweikern-analytics/g,
            "/loesungen/zweikern-analytics",
          )
      : "";
  }

  function setResource(_resource: string) {
    resource.value = _resource;
  }

  function storyResource(story: Object) {
    return story.default_full_slug.split("/")[0];
  }

  function storyResourceHeading(story: Object) {
    return t(`news.resources.${storyResource(story)}.title`);
  }

  return {
    items,
    totalItemsCount,
    countTotalItems,
    page,
    mediaTypes,
    topics,

    setResource,
    seeMore,
    all,
    bindItemCard,
    isPodcast,
    featuredItems,
    show,
    link,
    bgColor,
    keyvisual,
    generateLink,
    addTopChoices,
    // getLinks,
    parseContent,

    ...toRefs(store),
    isFilteredMediaType,
    filterMediaType,
    isFilteredTopic,
    filterTopic,
    hasFilteredMediaType,

    findArticleLink,
    previousArticle,
    nextArticle,

    // Single item
    storyResourceHeading,
  };
};
